.operate_btn[data-v-4221ce4b] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item_icon[data-v-4221ce4b] {
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-4221ce4b] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon p[data-v-4221ce4b] {
  display: inline-block;
  font-size: 13px;
}